import React, { useEffect } from "react";
import '@aws-amplify/ui-react/styles.css';
import ReactDOM from "react-dom/client";
import { Amplify } from 'aws-amplify';
import awsConfig from './aws-exports';
import Login from './pages/Login';
import refreshToken from './components/Authentication/refreshToken'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

Amplify.configure(awsConfig);


const root = ReactDOM.createRoot(document.getElementById("root"));

function App() {
    useEffect(() => {
        const intervalId = setInterval(refreshToken, 3300000); // 3300000 => 55 minutes / token expire toutes les heures
        return () => clearInterval(intervalId);
    }, []);
    return (
        <React.StrictMode>
            <Router>
                <Routes>
                    <Route path="/connexion/index.html" element={<Login />} />
                </Routes>
            </Router>
        </React.StrictMode>
    )
}
root.render(<App />);
