import React, { useEffect, useState } from 'react';
import { signIn } from 'aws-amplify/auth';


function Header({ titleHeader }) {
    const [setUser] = useState({});
  
    useEffect(() => {
      const getUser = async () => {
        try {
          const currentUser = await signIn.currentAuthenticatedUser();
          setUser(currentUser);
        } catch (error) {}
      };
      getUser();
    });
  
    const handleSignOut = async () => {
      try {
        await signIn.signOut();
        window.location.replace('/connexion');
      } catch (error) {}
    };
  
    return (
        <header>
            <title>Documentation API</title>
            <meta charset="utf-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            <link rel="icon" type="image/jpg" href="./favicon.jpg"/>
            <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet" />
            <style>
                {
                    `:root {
                        --orisha-dark: #333;
                        --orisha-dark-purple: #462383
                        --orisha-pink: #e8005f
                        --orisha-yellow: #feb702
                        --orisha-purple: #6b40a3
                        --orisha-white: #ffffff
                        --orisha-light-gray: #f7f7f7
                    }

                    body {
                        margin: 0;
                        padding: 0;
                        font-family: Poppins;
                        background-color: #462383;
                    }

                    .nav {
                        background-color: #fff;
                        padding: 5px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .logo img {
                        width: 10rem;
                        height: 3rem;
                    }

                    h1 {
                        color: #462383;
                        text-align: center;
                        font-size: 24px;
                        margin-bottom: 20px;
                    }

                    ul {
                        list-style: none;
                        padding: 0;
                        margin: 0;
                    }

                    li {
                        padding: 15px;
                        border-bottom: 1px solid #462383;
                    }
                    li:last-child {
                        border-bottom: none;
                    }

                    a {
                        text-decoration: none;
                        color: #462383;
                        transition: color 0.2s ease;
                    }

                    a:hover {
                        color: #6b40a3;
                    }

                    .container {
                        max-width: 700px;
                        margin: 30px auto;
                        padding: 10px;
                        border: 1px solid #ddd;
                        border-radius: 10px;
                        box-shadow: -3px -3px 3px #feb702, 3px 3px 3px #e8005f;
                        background-color: #fff;
                    }
                `}
            </style>
        <h1 className="text-center title-header m-4" style={{color:'#fff'}}>{titleHeader}</h1>
    </header>
    );
}
export default Header;