import React from 'react'
import Authentication from '../Authentication';


function FormLogin() {
  return (
    <div className="container-fluid mt-5 d-flex justify-content-center">
      <div className="boxed" style={{
        maxWidth:"700px",
        margin: "30px auto",
        padding:"10px",
        border:"1px solid #ddd",
        borderRadius: "10px",
        boxShadow: "-3px -3px 3px #feb702, 3px 3px 3px #e8005f",
        backgroundColor: "#fff"
      }}>
        <Authentication/>
      </div>
    </div>
  )
}  

export default FormLogin