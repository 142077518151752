import { signIn } from 'aws-amplify/auth';


async function refreshSession() {
  try {
    await signIn.currentSession();
  } catch (error) {
        console.error(error);
        signIn.signOut();
  }
}

export default refreshSession;
